<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="w-full overflow-hidden">
    <div :class="['flex flex-wrap p-0 -m-6', { 'flex-row-reverse': reverse }]">
      <div class="w-full md:w-4/12 flex items-center flex-auto m-6">
        <NuxtImg
          v-if="media"
          class="w-full mx-auto"
          :src="media?.url || ''"
          :alt="media?.alternativeText || ''"
          loading="lazy"
        />
      </div>
      <div class="w-full md:w-4/12 flex flex-col flex-auto m-6">
        <Icon
          v-if="iconExt"
          :name="iconExt"
          :class="[
            'block self-start p-2.5 mb-4 text-2xl text-white rounded',
            `bg-${bgColorIconExt}`
          ]"
        />
        <h2 v-if="title" class="font-bold text-2xl text-gray-dark mb-6'" v-text="title" />
        <p
          v-if="description"
          class="mb-6 text-lg leading-[23px] text-gray-dark"
          v-text="description"
        />
        <ul>
          <li v-for="(item, index) in list" :key="index" class="flex mb-4 items-baseline">
            <Icon :name="item.icon" :class="classIconList" class="mr-2 translate-y-1 text-2xl" />
            <div class="flex flex-col">
              <span
                v-if="item.titleList"
                class="font-ubuntu font-bold text-lg mb-3 text-gray-dark"
                v-html="item.titleList"
              />
              <Markdown :content="item.content" :class="classListText" />
            </div>
          </li>
        </ul>
        <div v-if="sectors.length" class="flex mt-6 mb-8">
          <icon
            v-for="(sector, index) in sectors"
            :key="index"
            :name="sector"
            class="first:lg:ml-7 flex items-center justify-center w-12 h-12 bg-blue-medium text-white text-2xl md:mr-2 lg:mr-6 rounded-full"
          />
        </div>

        <nuxt-link
          v-if="link.url"
          :to="link.url"
          class="flex items-center text-blue font-ubuntu text-sm font-bold"
        >
          {{ link.text }}
          <icon name="pt-angle-right" class="text-blue text-lg ml-3.5" />
        </nuxt-link>
        <a
          v-if="button.label"
          class="btn btn__orange mt-6"
          :href="button.url || TOOLS_BASE_URL"
          v-text="button.label"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { TOOLS_BASE_URL } from '~/utils/enums';
import Icon from '@/components/Icon';
import Markdown from '@/components/Markdown';

export default {
  name: 'PtToolsBanner',
  components: {
    Icon,
    Markdown
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    sectors: {
      type: Array,
      default: () => []
    },
    media: {
      type: Object,
      default: () => ({})
    },
    classIconList: {
      type: String,
      default: 'text-green text-lg'
    },
    iconExt: {
      type: String,
      default: ''
    },
    bgColorIconExt: {
      type: String,
      default: 'green-very-light'
    },
    classListText: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    reverse: {
      type: Boolean,
      default: false
    },
    button: {
      type: Object,
      default: () => ({})
    },
    link: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      TOOLS_BASE_URL
    };
  }
};
</script>
