<template>
  <Section padding-y="py-14 lg:pt-32 lg:pb-24" grid="E">
    <template #header>
      <div
        class="flex flex-col items-center max-w-4xl mx-auto px-4 pb-16 lg:pb-20 text-center space-y-4"
      >
        <span class="font-ubuntu font-bold text-gray-dark text-3xl mb-6" v-text="title" />
        <p class="text-lg text-gray" v-text="description" />
      </div>
    </template>
    <template #content>
      <div class="-my-8 lg:-my-12">
        <ul class="flex flex-wrap justify-center">
          <template v-for="(item, index) in list" :key="index">
            <li
              v-if="!!item.status === item.status ? Boolean(item.status) !== false : true"
              :key="index"
              class="w-full sm:w-6/12 lg:w-4/12 lg:max-w-lg flex-auto px-6 my-8 lg:my-12"
            >
              <div class="flex flex-col items-center text-center">
                <Icon
                  :name="item.icon"
                  :class="`bg-${bgIcons} text-${colorIcons} text-${sizeIcons}`"
                  class="p-2.5 rounded h-fit w-fit"
                />
                <span class="font-ubuntu font-bold text-lg my-4" v-text="item.title" />
                <p class="text-gray" v-text="item.description" />
              </div>
            </li>
          </template>
        </ul>
      </div>
    </template>
  </Section>
</template>

<script>
import Section from '@/components/Sections';
export default {
  name: 'PtBenefits',
  components: {
    Section
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    bgIcons: {
      type: String,
      default: 'green-very-light'
    },
    colorIcons: {
      type: String,
      default: 'white'
    },
    sizeIcons: {
      type: String,
      default: '2xl'
    },
    description: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    }
  }
};
</script>
